<template>
  <div class="home-container">
    <my-nav-bar title="首页" />
    <div ref="appMainContainer" class="app-main-container">
      <van-sticky v-if="notices.length > 0" offset-top="1.22667rem" :container="appMainContainer" class="notice-container">
        <template>
          <van-notice-bar :scrollable="false" left-icon="volume-o">
            <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :show-indicators="false"
            >
              <van-swipe-item v-for="(notice, index) in notices" :key="index">{{ notice }}</van-swipe-item>
            </van-swipe>
          </van-notice-bar>
        </template>
      </van-sticky>
      <div
        v-for="(group, index) in groupData"
        :key="index"
        class="home-container__grid"
      >
        <van-image
          width="2.72rem"
          height="0.42666rem"
          class="home-container__group-title"
          :src="require('@/assets/images/'+ group.icon +'.png')"
        />
        <van-grid
          :border="false"
          square
          :gutter="15"
          clickable
          icon-size="21px"
          column-num="4"
        >
          <van-grid-item
            v-for="(menu, index2) in group.menus"
            :key="index2"
            :class="'home-container__grid-item home-container__' + menu.icon"
            :text="menu.name"
            icon-prefix="mhj-icon"
            :icon="menu.icon"
            :to="menu.route"
            :badge="menu.badge"
          />

        </van-grid>
      </div>
    </div>
    <van-dialog v-model="showAlerts" title="告警" show-cancel-button cancel-button-text="今日不再提醒" @cancel="handleAlertCancel" @confirm="showAlerts = false">
      <van-cell v-for="(alert, index) in alerts" :key="index" :title="alert.text" :is-link="alert.path.length > 0" center :to="alert.path" />
    </van-dialog>
  </div>
</template>

<script>
import homeApi, { cancelAlert, getAlerts } from '@/api/home'
import myNavBar from '@/components/my-nav-bar'
export default {
  name: 'Home',
  components: { myNavBar },
  data() {
    return {
      groupData: null,
      notices: [],
      alerts: [],
      appMainContainer: null,
      showAlerts: false
    }
  },
  activated() {
    document.body.scrollTop = this.$route.meta.scrollTop
    this.getCount()
    getAlerts().then(res => {
      this.alerts = res.data
      this.showAlerts = this.alerts.length > 0
    })
  },
  mounted() {
    this.appMainContainer = this.$refs.appMainContainer
  },
  methods: {
    getCount() {
      this.beginLoad()
      homeApi.count().then(res => {
        this.endLoad()
        this.groupData = res.data.group
        this.notices = res.data.notices
        if (this.notices.length > 0) {
          this.noticeText = this.notices[0]
        }
      })
    },
    handleAlertCancel() {
      cancelAlert()
    }
  }
}
</script>

<style lang="scss">
.home-container {
  &__grid-item {
     .van-grid-item {
       &__content {
         box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
         border-radius: 6px;
       }
       &__text {
        text-align: center;
       }
     }
   }
}
</style>

<style lang="scss" scoped>
 .home-container {
   padding-bottom: 50px;
   &__grid {
     padding-top: 15px;
     background-color: #fff;
     margin-bottom: 10px;
   }
   &__group-title {
     /*width: 102px;*/
     /*height: 16px;*/
     margin: 15px;
   }
   &__cangkujianhuo, &__gaidanchuli, &__feiyongbaoxiao, &__shujuzonglan, &__caigoudan, &__tiaobochuku, &__paihang, &__shangjia, &__chuku,
   &__navicon-pdsp {
     color: #FFBF37;
   }
   &__beihuofuhe, &__peisongdingdan, &__zhuangchefuhe, &__peisongduizhang, &__cangkupandian, &__chayihua-, &__rukudan, &__baobiao, &__rukubaihuo,
   &__baifangguanli, &__yejidanbiticheng, &__zhuangche-xian {
     color: #24A8F4;
   }
   &__tuihuochuli, &__caigoudingdan, &__shouhuoyanshoudan, &__returnorder1111, &__huodong, &__daifukuan, &__pandian, &__tihuo, &__jihua {
     color: #7F65EE;
   }
   &__quyupaixu, &__paidanzhuangche, &__shuju, &__dingwei, &__tiaobodan, &__zhichu, &__caiwubaobiao, &__receipt-alt, &__shengouxiadan, &__saoyisao, &__peisongrenwu,
   &__yulan, &__shouyin {
     color: #4CC168;
   }
   &__tuihuoruku, &__zhuangchejianhuo, &__zhangdanmingxi, &__chajiabuqi-02-01, &__kucunyujing,
   &__quehuodingdanbiao, &__tiaoboruku, &__yingshoukuan, &__zhangdanguanli, &__yousejinshu, &__khfx,
   &__hedui, &__huaban40
    {
     color: #F65E5E;
   }
 }
 .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  .notice-container {
    height: 40px;
  }
  .mhj-icon-baifangguanli {
    font-size: 23px!important;
  }
  .mhj-icon-yejidanbiticheng {
    font-size: 28px!important;
  }
</style>
