var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "首页"
    }
  }), _c('div', {
    ref: "appMainContainer",
    staticClass: "app-main-container"
  }, [_vm.notices.length > 0 ? _c('van-sticky', {
    staticClass: "notice-container",
    attrs: {
      "offset-top": "1.22667rem",
      "container": _vm.appMainContainer
    }
  }, [[_c('van-notice-bar', {
    attrs: {
      "scrollable": false,
      "left-icon": "volume-o"
    }
  }, [_c('van-swipe', {
    staticClass: "notice-swipe",
    attrs: {
      "vertical": "",
      "autoplay": 3000,
      "show-indicators": false
    }
  }, _vm._l(_vm.notices, function (notice, index) {
    return _c('van-swipe-item', {
      key: index
    }, [_vm._v(_vm._s(notice))]);
  }), 1)], 1)]], 2) : _vm._e(), _vm._l(_vm.groupData, function (group, index) {
    return _c('div', {
      key: index,
      staticClass: "home-container__grid"
    }, [_c('van-image', {
      staticClass: "home-container__group-title",
      attrs: {
        "width": "2.72rem",
        "height": "0.42666rem",
        "src": require('@/assets/images/' + group.icon + '.png')
      }
    }), _c('van-grid', {
      attrs: {
        "border": false,
        "square": "",
        "gutter": 15,
        "clickable": "",
        "icon-size": "21px",
        "column-num": "4"
      }
    }, _vm._l(group.menus, function (menu, index2) {
      return _c('van-grid-item', {
        key: index2,
        class: 'home-container__grid-item home-container__' + menu.icon,
        attrs: {
          "text": menu.name,
          "icon-prefix": "mhj-icon",
          "icon": menu.icon,
          "to": menu.route,
          "badge": menu.badge
        }
      });
    }), 1)], 1);
  })], 2), _c('van-dialog', {
    attrs: {
      "title": "告警",
      "show-cancel-button": "",
      "cancel-button-text": "今日不再提醒"
    },
    on: {
      "cancel": _vm.handleAlertCancel,
      "confirm": function confirm($event) {
        _vm.showAlerts = false;
      }
    },
    model: {
      value: _vm.showAlerts,
      callback: function callback($$v) {
        _vm.showAlerts = $$v;
      },
      expression: "showAlerts"
    }
  }, _vm._l(_vm.alerts, function (alert, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "title": alert.text,
        "is-link": alert.path.length > 0,
        "center": "",
        "to": alert.path
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }